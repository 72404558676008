<template>
  <div id="email-verification">
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'EmailVerification',
  data () {
    return {
      otp: null
    }
  },
  methods: {
    ...mapActions({
      CONFIRM_EMAIL: 'CONFIRM_EMAIL'
    })
  },
  mounted () {
    this.otp = this.$route.params.otp
    this.CONFIRM_EMAIL({
      payload: { otp: this.otp },
      success: () => {
        this.$router.push({ name: 'auth1.sign-in1' })
      }
    })
  }
}
</script>

<style>

</style>
